var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "ui-input-group",
    class: _vm.$store.getters.themeClass
  }, [_vm.label ? _c('UiFormLabel', {
    staticClass: "label",
    attrs: {
      "for": `ui-${_vm.uid}`,
      "variant": _vm.variant
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")]) : _vm._e(), _c('div', _vm._g(_vm._b({
    staticClass: "variants",
    class: {
      hasDescriptions: _vm.hasDescriptions
    },
    attrs: {
      "id": `ui-${_vm.uid}`
    }
  }, 'div', _vm.$attrs, false), _vm.$listeners), [_vm._l(_vm.items, function (variant, i) {
    return [_c('div', {
      key: i,
      staticClass: "variant",
      class: {
        selected: variant.value === _vm.value
      },
      on: {
        "click": function ($event) {
          return _vm.onClick(variant);
        }
      }
    }, [_c('span', {
      staticClass: "text"
    }, [_vm._v(" " + _vm._s(variant.text) + " "), variant.icon ? [_c('div', {
      staticClass: "icon"
    }, [_c(variant.icon, {
      tag: "component"
    })], 1)] : _vm._e()], 2), _c('svg', {
      staticClass: "selected-mark",
      attrs: {
        "width": "20",
        "height": "16",
        "viewBox": "0 0 20 16",
        "fill": "none",
        "xmlns": "http://www.w3.org/2000/svg"
      }
    }, [_c('path', {
      attrs: {
        "d": "M6.33264 11.781L1.94197 7.3903L0.0566406 9.27563L6.33264 15.5516L19.2753 2.60897L17.39 0.723633L6.33264 11.781Z",
        "fill": "#316D92"
      }
    })])]), variant.description ? _c('div', {
      key: `d:${i}`,
      staticClass: "variant-description"
    }, [_vm._v(" " + _vm._s(variant.description) + " ")]) : _vm._e()];
  })], 2)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };