import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import PublicHeader from "./Header";
import PublicFooter from "./Footer";
import BillingManualForm from "@/components/Billing/ManualForm";
export default {
  meta: {
    title: "Успешная регистрация"
  },
  components: {
    PublicHeader,
    BillingManualForm,
    PublicFooter
  },
  data() {
    return {
      sentFeedback: null,
      order: null,
      orderLoaded: false
    };
  },
  async mounted() {
    if (!this.code) {
      this.orderLoaded = true;
      return;
    }
    try {
      const {
        order
      } = await this.$store.dispatch("billing/getManualOrder", {
        code: this.code
      });
      this.order = order;
    } catch (e) {
      var _e$response;
      if (![404, 409].includes((_e$response = e.response) === null || _e$response === void 0 ? void 0 : _e$response.status)) {
        throw e;
      }
    } finally {
      this.orderLoaded = true;
    }
  },
  computed: {
    code() {
      return this.$route.query.code;
    }
  },
  methods: {
    onComplete() {
      // this.$router.push("/");
    },
    async onSubmit() {
      const isValid = await this.validate(true);
      if (!isValid) {
        return;
      }
      const feedback = await this.$store.dispatch("feedback/send", {
        name: this.active_inputs.find(i => i.name === "name").model.trim(),
        email_address: this.active_inputs.find(i => i.name === "email_address").model.trim().toLowerCase(),
        phone_number: this.active_inputs.find(i => i.name === "phone_number").model.trim(),
        message: this.active_inputs.find(i => i.name === "message").model.trim()
      });
      this.sentFeedback = feedback;
    }
  }
};