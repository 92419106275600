var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "payment-form"
  }, [_c('UiForm', [_c('UiFormGrid', [_c('div', {
    staticClass: "full"
  }, [_c('UiFormVariantsInput', {
    attrs: {
      "items": _vm.paymentMethods,
      "id": _vm.getId('paymentMethod'),
      "label": "Метод оплаты"
    },
    model: {
      value: _vm.paymentMethod,
      callback: function ($$v) {
        _vm.paymentMethod = $$v;
      },
      expression: "paymentMethod"
    }
  })], 1), _c('div', {
    staticClass: "full"
  }, [_vm._v(" Сумма к оплате: "), _c('strong', [_vm._v(_vm._s(_vm.formatMoney(_vm.order.sum)) + " ")])]), _c('div', {
    staticClass: "full"
  }, [_c('UiButtonsGroup', [_c('UiButton', {
    attrs: {
      "variant": "blue"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onSubmit.apply(null, arguments);
      }
    }
  }, [_vm.paymentMethod === 'bank_invoice' ? [_vm._v(" Выставить счет ")] : [_vm._v("Перейти к оплате ")]], 2)], 1)], 1)])], 1)], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };